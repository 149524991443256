import React from 'react'
import "./Stories.css"
import icon_2 from "../../assets/images/Banner/stories-2.png"
import icon_3 from "../../assets/images/Banner/stories-3.png"
import icon_4 from "../../assets/images/Banner/stories-3.png"


const Stories = () => {
    const data = [
        {
          icon: icon_2,
          title: "A/C Repair",
          desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
        },
        {
          icon: icon_3,
          title: "Construction",
          desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
        },
        {
          icon: icon_4,
          title: "Technician",
          desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
        },
    ]
  return (
    <>
    <section className='categories-wrapper'>
      <div className='container'>
        <div className='stories-main'>
            <div className='categories-heading text-center mb-5'>
                <h2>Explore more projects</h2>
            </div>
        </div>

        <div className="row">
              {data.map((item) => (
                <div className='col-md-4'>
                <div className="item">
                  <div className="stories-time position-relative">
                    <div className="stories-icon">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className='stories-group'>

                    <div className='stories-heading'>
                        <h2>{item.title}</h2>
                        <div className='stories-description'>
                     
                    </div>
                    </div>
                  </div>
                  
                  </div>
                </div>
                </div>
              ))}
        </div>

        <p className='text-center text-underline'><a href='' className='text-underline text-dark'>View All</a></p>

    </div>
    </section>
    
    </>
  )
}

export default Stories