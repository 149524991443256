import React from 'react'
import App from "../../assets/images/Banner/app.png"
import AppStore from "../../assets/images/Banner/app-store.png"
import PlayStore from "../../assets/images/Banner/play-store.png"
import "./Application.css"



const Application = () => {
    return (
        <section className='application-wrapper pt-5 pb-5'>
            <div className='container'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-md-6'>
                        <img src={App} alt="" className='arbiteck-man'/>
                    </div>
                    <div className='col-md-6'>
                        <h2>A whole new way for <br/>
                            Home Improvement!</h2>
                        <p>What sets successful people apart from the pack?
                        <br/>Is it luck, money, good lucks, or talent.</p>
                        <div className='d-flex custom-banner'>
                            <a href="#">
                                <img src={AppStore} alt="app-store" />
                            </a>
                            <a href="#">
                                <img src={PlayStore} alt="play-store" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Application