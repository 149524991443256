import React from 'react'
import icon_2 from "../assets/images/Banner/stories-2.png"
import icon_3 from "../assets/images/Banner/stories-3.png"
import icon_4 from "../assets/images/Banner/stories-3.png"

const Cards = ({heading ,titles , desc ,titles1 , desc1 ,titles2 , desc2}) => {
    const data = [
        {
          icon: icon_2,
          title: titles,
          desription: desc,
        },
        {
          icon: icon_3,
          title: titles1,
          desription: desc1,
        },
        {
          icon: icon_4,
          title: titles2,
          desription: desc2,
        },
    ]
    return (
        <>
        <section className='categories-wrappersssf p-0'>
            <div className='container'>
                <div className='stories-main'>
                    <div className='categories-heading text-center mb-5'>
                        <h2>{heading}</h2>
                    </div>
                </div>

                <div className="row">
                    {data.map((item) => (
                        <div className='col-md-4'>
                            <div className="item">
                                <div className="stories-time position-relative">
                                    <div className="stories-icon">
                                        <img src={item.icon} alt="" />
                                    </div>
                                    <div className='stories-group'>

                                        <div className='stories-heading'>
                                            <h2>{item.title}</h2>
                                            <div className='stories-description'>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        </>
    )
}

export default Cards