import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import Review from "../../assets/images/Banner/reviews.png";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Background from "../../assets/images/Banner/background-review.png";


const Reviews = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    autoHeight: true,
    rewind: false,
    dots: true,
    lazyLoad: "true",
    lazyContent: "true",
    loop: false,
    touchDrag: true,
    mouseDrag: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        autoWidth: true,
      },
      400: {
        items: 1,
        autoWidth: true,
      },
      600: {
        items: 1,
        autoWidth: true,
      },
      700: {
        items: 2,
        autoWidth: true,
      },
      1000: {
        items: 2,
        autoWidth: true,
      },
      1200: {
        items: 2,
        autoHeight: true,
      },
    },
  };
  const data = [
    {
      icon: Review,
      description: "Highly recommended!",
      position: "John D",
      title: "Client",
    },
    {
      icon: Review,
      description: "Your platform made it incredibly easy for me to hire skilled professionals for my household needs.",
      position: "Emily L",
      title: "Client",
    },
    {
      icon: Review,
      description: "I was skeptical about using an online platform to hire services, but your platform exceeded my expectations.",
      position: "Lisa R",
      title: "Client",
    }
  ]
  return (
    <section className='reviews bg-white'>
      <div className='container'>
        <div className='stories-main'>
          <div className='categories-heading text-center'>
            <h2>What our Customers say!</h2>
            <p>"In the new era of technology we look in the<br />
              future with certainty pride for </p>
          </div>
        </div>
        <div className='w-75 mr-auto d-block'>
        <OwlCarousel className="owl-theme" {...options}>
          {data.map((item) => (
            <div className="item">
              <div className='review-comment'>
                  <p>{item.description}</p>
              </div>
              <div className="review-time d-flex align-items-center">
                <div className="review-icon">
                  <img src={item.icon} alt="" />
                </div>
                <div className='review-description px-2'>
                  <h5 className="review-text">{item.title}</h5>
                  <p>{item.position}</p>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
        </div>


      </div>
    </section>
  )
}

export default Reviews