import React from 'react'

const Privacy = () => {
    return (
        <>
            <section className='terms-banner'>
                <div className='container'>
                    <div className='terms-inner'>
                        <h1>Privacy Policy</h1>
                        <p>Last Updated: November 7, 2022</p>
                        <p className='mb-5'><b>IMPORTANT NOTICE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION AND CLASS
                            ACTION WAIVER. IT AFFECTS YOUR LEGAL RIGHTS AS DETAILED IN THE ARBITRATION AND CLASS
                            ACTION WAIVER SECTION BELOW. PLEASE READ CAREFULLY.</b></p>
                        <div className='terms-main'>
                            <h3>ACCEPTANCE OF THESE TERMS</h3>
                            <h6>What can I do to help pros at this time?</h6>
                            <p>Keep supporting pros and small businesses when safe and possible.
                                Keep your distance. Wear a cloth face cover. Make sure your space is well ventilated.
                                Hire remote services, or services that maintain distance between you and the pro. Customers can look for the “Offers remote services” badge in search results and pro profiles.
                                Ask pros about their ability to provide no-contact delivery options or remote services.
                                Use digital payments instead of cash or check.
                                Ask pros about buying gift cards or buying packages for future work. This is often a great way to get a discount and support small businesses through this tough time. Customers can look for the “Discounts available” badge in search results and pro profiles.
                                Consider tipping, or paying now for work that you’ve postponed.
                                What safety measures are pros taking?
                                Thumbtack is encouraging pros to review CDC safety guidelines and pledge to take preventive measures to limit their — and your — exposure to COVID-19, including:

                                Maintain 6 feet of distance from customers
                                Wear cloth face covers during the job
                                Wear gloves during the job
                                Disinfect surfaces touched during the job
                                Customers can see if a pro has pledged to take preventive measures in their profile.
                            </p>
                            <h3>ACCEPTANCE OF THESE TERMS</h3>
                            <h6>What can I do to help pros at this time?</h6>
                            <p>Keep supporting pros and small businesses when safe and possible.
                                Keep your distance. Wear a cloth face cover. Make sure your space is well ventilated.
                                Hire remote services, or services that maintain distance between you and the pro. Customers can look for the “Offers remote services” badge in search results and pro profiles.
                                Ask pros about their ability to provide no-contact delivery options or remote services.
                                Use digital payments instead of cash or check.
                                Ask pros about buying gift cards or buying packages for future work. This is often a great way to get a discount and support small businesses through this tough time. Customers can look for the “Discounts available” badge in search results and pro profiles.
                                Consider tipping, or paying now for work that you’ve postponed.
                                What safety measures are pros taking?
                                Thumbtack is encouraging pros to review CDC safety guidelines and pledge to take preventive measures to limit their — and your — exposure to COVID-19, including:

                                Maintain 6 feet of distance from customers
                                Wear cloth face covers during the job
                                Wear gloves during the job
                                Disinfect surfaces touched during the job
                                Customers can see if a pro has pledged to take preventive measures in their profile.
                            </p>
                            <h3>ACCEPTANCE OF THESE TERMS</h3>
                            <h6>What can I do to help pros at this time?</h6>
                            <p>Keep supporting pros and small businesses when safe and possible.
                                Keep your distance. Wear a cloth face cover. Make sure your space is well ventilated.
                                Hire remote services, or services that maintain distance between you and the pro. Customers can look for the “Offers remote services” badge in search results and pro profiles.
                                Ask pros about their ability to provide no-contact delivery options or remote services.
                                Use digital payments instead of cash or check.
                                Ask pros about buying gift cards or buying packages for future work. This is often a great way to get a discount and support small businesses through this tough time. Customers can look for the “Discounts available” badge in search results and pro profiles.
                                Consider tipping, or paying now for work that you’ve postponed.
                                What safety measures are pros taking?
                                Thumbtack is encouraging pros to review CDC safety guidelines and pledge to take preventive measures to limit their — and your — exposure to COVID-19, including:

                                Maintain 6 feet of distance from customers
                                Wear cloth face covers during the job
                                Wear gloves during the job
                                Disinfect surfaces touched during the job
                                Customers can see if a pro has pledged to take preventive measures in their profile.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Privacy