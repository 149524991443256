import React from 'react'
import banner from '../assets/images/Banner/pros-banner.png'
import Fee from '../assets/images/Banner/no-fee.png'
import Customer from '../assets/images/Banner/customer.png'
import Flexible from '../assets/images/Banner/flexible.png'
import icon_1 from '../assets/images/Banner/icon_1.png'
import icon_2 from '../assets/images/Banner/icon_2.png'
import icon_3 from '../assets/images/Banner/icon_3.png'
import icon_4 from '../assets/images/Banner/icon_4.png'
import { Cta, Discover } from '../components/index'
import work from '../assets/images/Banner/work-3.svg'
import cancel from '../assets/images/Banner/cancel-icon.png'
import magnifying from '../assets/images/Banner/magnifying.png'
import location from '../assets/images/Banner/location.png'


const Pros = () => {
    const data = [
        {
            image: Fee,
            title: "No Subscription Fee",
            description: "What sets successful people apart from the pack? Is it luck, money, good lucks, or talent."
        },
        {
            image: Customer,
            title: "Great Customers",
            description: "What sets successful people apart from the pack? Is it luck, money, good lucks, or talent."
        },
        {
            image: Flexible,
            title: "Control and Flexibility",
            description: "What sets successful people apart from the pack? Is it luck, money, good lucks, or talent."
        }
    ];
    const checkLeft = [
        {
            title: "No entry or annual fees",
        },
        {
            title: "Customer picks you",
        },
        {
            title: "Set your own lead prices",
        },
        {
            title: "Competition limit",
        },
        {
            title: "Phone numbers on all leads",
        },
        {
            title: "Book jobs instantly",
        },
    ];
    const checkRight = [
        {
            title: "No entry or annual fees",
        },
        {
            title: "Customer picks you",
        },
        {
            title: "Set your own lead prices",
        },
        {
            title: "Competition limit",
        },
        {
            title: "Phone numbers on all leads",
        },
        {
            title: "Book jobs instantly",
        },
    ];
    const love = [
        {
            image: icon_1,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            image: icon_2,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            image: icon_3,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            image: icon_4,
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        }
    ];

    return (
        <>
            <section className='pros-banner'>
                <div className='container'>
                    <div className='pros-inner'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <img src={banner} alt="" />
                            </div>
                            <div className='col-md-6'>
                                <div className='pros-job'>
                                    <h1>Get Jobs!</h1>
                                    <p>Thousands of homeowners connect with the right pro every week for their remodeling projects.</p>
                                    {/* <div className='pro-input'>
                                        <span className='location-span'><img src={magnifying} alt="search icon" /><input type="text" placeholder='e.g. House Cleaning'/></span>
                                        <span className='location-span'><img src={location} alt="location icon" /><input type="text" placeholder='e.g. 10029 or New York'/></span>
                                        <p>try searching for a <b>Plumber</b>, <b>Electrician</b> or <b>handyman</b></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='inner-pros-section'>
                <div className='container'>
                    <div className='heading text-center'>
                        <h2>Why Arbeittech?</h2>
                    </div>
                    <div className='row'>
                        {data.map((item, i) => (
                            <div className='col-md-4'>
                                <div className='d-flex align-items-center'>
                                    <div className='pros-image'>
                                        <img src={item.image} alt="icons for pros" />
                                    </div>
                                    <div className='pros-titles'>
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section className='inner-section-pros-2'>
                <div className='container'>
                    <div className='custom-width'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='pros-checks'>
                                    <ul>
                                        <h6>OTHER SERVICES</h6>
                                        {checkLeft.map((item, i) => (
                                            <li className='d-flex custom-icon'><img src={work} alt='checks' /> {item.title}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='pros-checks'>
                                    <ul>
                                        <h6>OTHER SERVICES</h6>
                                        {checkRight.map((item, i) => (
                                            <li className='d-flex custom-icon'>{i < 4 ? (<img src={cancel} alt='checks' />) : (<img src={work} alt='checks' />)} {item.title}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pros-section-3'>

                <div className='inner-heading text-center'>
                    <h2>why pros love arbeittech</h2>
                </div>
                <div className='inner-pros-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6'>

                            </div>
                            <div className='col-md-6'>
                                {love.map((item, i) => (
                                    <div className='pros-love'>
                                        <div className='d-flex'>
                                            <div className='pros-love-image'>
                                                <img src={item.image} alt="pros icons" />
                                            </div>
                                            <div className='pros-love-title'>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pros-section-3'>
                <div className='container'>
                    <div className='inner-heading text-center'>
                        <h2>why pros love arbeittech</h2>
                    </div>
                    <Discover />
                    <Cta />

                </div>
            </section>





        </>

    )
}

export default Pros