import React from 'react'
import Review from '../assets/images/Banner/review-banner.png'
import { Cta ,Reviews} from '../components'
import HelpCards from '../global/HelpCards'

const Reviewss = () => {
  return (
    <>
      <div className='review-banner'>
        <div className='container'>
          <div className='custom-r-w'>
            <div className='row align-items-center'>
              <div className='col-md-6'>
                <div className='img-r'>
                  <img src={Review} alt="review-pro" />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='r-text'>
                  <h1 className='r-heading'>
                    Professionals like you love Arbeittech
                  </h1>

                  <p>
                    Thumbtack has helped hundreds of thousands of professionals
                    grow their businesses. Hear what they have to say.
                  </p>
                  <a href="/sign" className='custom-btn'>Become a Pro</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-trans'>
      <Reviews />
      </div>
      <section className='r-section-1'>
        <div className='container'>
          <HelpCards />
        </div>
      </section>
      <Cta />

    </>
  )
}

export default Reviewss