import React from 'react'
import cta from "../../assets/images/Banner/cta.png"
import "./Cta.css"

const Cta = () => {
  return (
    <>
        <div className='categories-wrapper mt-40'>
      <div className='container'>
    <div className="row">
                <div className='col-md-12'>
                <div className="item">
                  <div className="cta-time">
                    <div className="cta-icon">
                      <img src={cta} alt="images-cta" />
                    </div>
                    <div className='cta-group'>

                    <div className='cta-heading'>
                        <h2>Open for Business</h2>
                        <div className='cta-description'>
                        <p>If you have any of the skill of the service we are delivering so, you are more than welcome.</p>
                    </div>
                    </div>
                   
                      <div className='cta-btn'>
                        <button>Join as a pro</button>
                      </div>
                  </div>
                  
                  </div>
                </div>
                </div>
        </div>
        </div>
        </div>
    
    </>
  )
}

export default Cta