import React from 'react'
import BlogCards from '../global/BlogCards'
import related from "../assets/images/Banner/related-banner.png"


const Blog = () => {
    const data = [
        {
            image: related,
            title: "Staying Safe For the Holidays"
        },
        {
            image: related,
            title: "Staying Safe For the Holidays"
        },
        {
            image: related,
            title: "Staying Safe For the Holidays"
        },
        {
            image: related,
            title: "Staying Safe For the Holidays"
        }
    ];
    return (
        <>
            <section className='blog-banner'>
                <div className='container'>
                    <div className='blogs-inner banner-main'>
                        <h1>Blogs</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </section>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7'>
                        <BlogCards />
                        <BlogCards />
                        <BlogCards />
                    </div>
                    <div className='col-md-5'>
                        <div className='search-post'>
                            <h5>Search Post</h5>
                            <input type="text" className='form-control' placeholder="Search Blogs" />
                        </div>
                        <div className='search-post'>
                            <h5>Recent Post</h5>
                            {data.map((item, i) => (
                                <div className='d-flex mb-4 custom-mr align-items-center'>
                                    <div className='related-image'>
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className='related-title'>
                                        <h5>{item.title}</h5>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Blog