import React from 'react'
import HelpCards from '../global/HelpCards'
import HelpTabs from '../global/HelpTabs'

const Help = () => {
    return (
        <>
            <section className='help-banner'>
                <div className='container'>
                    <div className='help-inner'>
                        <div className='banner-main'>
                            <div className='banner-heading pb-2'>
                                <h1>How can we help?</h1>
                            </div>
                            <div className='banner-paragraph pb-3'>
                                <p>Thousands of homeowners connect with the right pro every week for their remodeling projects.</p>
                            </div>
                            <div className='banner-input pb-3'>

                                <label className='position-relative w-100 d-flex justify-content-center align-items-center'>
                                    <input type="text" placeholder='What services do you need?' />
                                    <span className='zip-code'>74600</span>
                                </label>
                                <button className='button'>Get started</button>

                            </div>
                            <div className='banner-input-description'>
                                <p>Suggestions: <span>Plumber</span>, <span>Electrician</span> or <span>handyman</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='help-section'>
                <div className='container'>
                    <h2 className='text-center'>Recommended</h2>
                    <div className='mt-5'>
                        <HelpCards />
                    </div>
                </div>
            </section>
            <section className='help-section'>
                <div className='container'>
                    <HelpTabs />
                </div>
            </section>
        </>
    )
}

export default Help