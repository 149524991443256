import React from 'react'
import thumb from "../assets/images/Banner/blogs-thumb.png"
import author from "../assets/images/Banner/author.png"

const BlogCards = () => {
  return (
    <>
    <div className='blogs-cards'>
        <div className='blogs-inner-div'>
            <div className='blogs-image'>
                <img src={thumb} alt="" />
            </div>
            <div className='date'>
                <p>09 Dec, 2023</p>
            </div>
            <div className='titles'>
                <h4>Our Ultimate Spring Cleaning Guide.</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
            <div className='card-footers'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='cards-footer-left'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='footer-image'>
                                <img src={author} alt="" />
                            </div>
                            <div className='footer-author'>
                                <h6>Cyrus Melabo</h6>
                                <span>Author</span>
                            </div>
                        </div>
                    </div>
                    <div className='development-links'>
                        <a href="">Development</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default BlogCards