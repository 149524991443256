import React from 'react'
import { Container, Row, Accordion } from 'react-bootstrap';
import Faq from "../../assets/images/Banner/faqs.png";
import "./Faqs.css"
import Cta from '../cta/Cta';

const Faqs = () => {
    return (
        <section className='faqs-wrapper'>
            <Container>
                <Row>
                    <div className="col-12 mt-5 col-md-6 mx-auto ms-md-auto mt-5 mt-md-0">
                        <div className='stories-main'>
                            <div className='categories-heading'>
                                <h2>Frequently Asked!</h2>
                                <p>Chetah gives you the blocks & components you need to create a truly professional website, landing page or admin panel for your SaaS.</p>
                            </div>
                        </div>

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How do I register on your platform?</Accordion.Header>
                                <Accordion.Body>
                                Visit our website and click on the "Sign up as provider" button. Fill out the required information, including your name, contact details, skills, and work experience. Once registered, you can explore job listings, apply for positions, and showcase your expertise to potential employers.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What type of services can companies offer on your platform?</Accordion.Header>
                                <Accordion.Body>
                                Our platform allows companies to offer a wide range of services. Whether you're a plumber, electrician, house cleaner, or provide other professional services, you can create a company profile and list the services you offer. This allows job seekers to find and connect with you based on their specific service needs.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How do I schedule a job on your platform?</Accordion.Header>
                                <Accordion.Body>
                                Scheduling a job on our platform is simple. Once you have logged in to your account as a company, go to the job management section and click on "Schedule Job." Fill in the necessary details, such as the job type, date, time, and location. You can also assign the job to a specific job seeker if desired.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can I upload documents or attachments using the mobile app?</Accordion.Header>
                                <Accordion.Body>
                                Absolutely! The mobile app allows you to upload documents or attachments directly from your device. Whether it's your resume, certificates, or any other relevant documents, you can easily attach and send them when applying for jobs or communicating with employers.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Is my financial information shared with service providers?</Accordion.Header>
                                <Accordion.Body>
                                No, your financial information is not shared with service providers. When you make a payment, your payment details are securely processed, and the service provider receives only the necessary information to verify the payment. Your financial information remains confidential and protected.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="col-12 mt-5 col-md-6 mx-auto ms-md-auto mt-5 mt-md-0">
                        <div>
                            <img src={Faq} alt="" className='mr-auto width-70'/>
                        </div>
                    </div>
                </Row>
            </Container>
        </section >
    )
}

export default Faqs