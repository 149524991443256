import React from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';



const HelpTabs = () => {
    const data = [
        {
            title: "Get started",
            desc: "for me to be able to interact, connect with and people to connect with me to spread that love, to see the smiles on their faces."
        },
        {
            title: "Targeting preferences",
            desc: "for me to be able to interact, connect with and people to connect with me to spread that love, to see the smiles on their faces."
        },
        {
            title: "Profile and reviews",
            desc: "for me to be able to interact, connect with and people to connect with me to spread that love, to see the smiles on their faces."
        },
        {
            title: "Payments",
            desc: "for me to be able to interact, connect with and people to connect with me to spread that love, to see the smiles on their faces."
        },
        {
            title: "Help",
            desc: "for me to be able to interact, connect with and people to connect with me to spread that love, to see the smiles on their faces."
        },
        {
            title: "Español",
            desc: "for me to be able to interact, connect with and people to connect with me to spread that love, to see the smiles on their faces."
        },
    ];
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills" className="flex-column mb-5">
                            <Nav.Item className='d-flex flex-1 justify-content-center align-items-center'>
                                <Nav.Link eventKey="first">Help for Pros</Nav.Link>
                                <Nav.Link eventKey="second">Help for Customers</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className='row'>
                                    {data.map((item, i) => (
                                        <div className='col-md-4'>
                                            <div className='help-box'>
                                                <h5>{item.title}</h5>
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className='row'>
                                    {data.map((item, i) => (
                                        <div className='col-md-4'>
                                            <div className='help-box'>
                                                <h5>{item.title}</h5>
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>


        </>
    )
}

export default HelpTabs