import Container from 'react-bootstrap/Container';
import logo from "../../assets/images/Banner/logo.svg"
import { Navbar, NavDropdown, Nav } from "react-bootstrap";


function Header() {
  return (
    <>
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/"><img src={logo} alt="" /></Navbar.Brand>
          <Nav className="ml-auto">

            <NavDropdown title='Login' id="basic-nav-dropdown">
                  <NavDropdown.Item
                    href="https://app.arbeittak.com/login"
                    className={"nav-link text-main px-md-3"}
                  >
                    Customer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://app.arbeittak.com/provider/login"
                    className={"nav-link text-main px-md-3"}
                  >
                    Pros
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://app.arbeittak.com/fleet/login"
                    className={"nav-link text-main px-md-3"}
                  >
                    Fleet
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title='Sign up' id="basic-nav-dropdown">
                  <NavDropdown.Item
                    href="https://app.arbeittak.com/register"
                    className={"nav-link text-main px-md-3"}
                  >
                    Customer
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://app.arbeittak.com/provider/register"
                    className={"nav-link text-main px-md-3"}
                  >
                    Pros
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://app.arbeittak.com/fleet/signup"
                    className={"nav-link text-main px-md-3"}
                  >
                    Fleet
                  </NavDropdown.Item>
                </NavDropdown>



                <Nav.Link href="#home" className='opacity-0'>Home</Nav.Link>
            <Nav.Link href="#features" className='opacity-0'>Features</Nav.Link>
            <Nav.Link href="#pricing" className='opacity-0'>Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;