import {Header , Footer, Application} from './components/index'
import './App.css';
import { Routes, Route } from "react-router-dom";
import Pros from './pages/Pros';
import Home from './pages/Home';
import Categories from './pages/Categories';
import Search from './pages/Search';
import About from './pages/About';
import Blog from './pages/Blog';
import Question from './pages/Question';
import HowWroks from './pages/HowWroks';
import Safety from './pages/Safety';
import Covid from './pages/Covid';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Help from './pages/Help';
import Reviewss from './pages/Reviews';

function App() {
  return (
    <>
    
    <Header />
    <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/pros" element={<Pros />} />
        <Route exact path="/categories" element={<Categories />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/app" element={<Application />} />
        <Route exact path="/faqs" element={<Question />} />
        <Route exact path="/work" element={<HowWroks />} />
        <Route exact path="/help" element={<Help />} />
        <Route exact path="/safety" element={<Safety />} />
        <Route exact path="/covid" element={<Covid />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/reviews" element={<Reviewss />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
