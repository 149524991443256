import React from 'react'
import logo from "../../assets/images/Banner/logo.svg"
import "./Footer.css"


const Footer = () => {
    const data = [
        {
            heading: "About Us",
            li_1: <>{<a href="/about">About</a>}</>,
            li_2: <>{<a href="/reviews">Reviews</a>}</>,
            li_5: <>{<a href="/blog">Blog</a>}</>,
        },
        {
            heading: "Customers",
            li_1: <>{<a href="/pros">How to use Arbeittech</a>}</>,
            li_2: <>{<a href="/faqs">Questions</a>}</>,
            li_4: <>{<a href="/categories">Services near me</a>}</>,
            li_5: <>{<a href="/search">Home resource center</a>}</>,
        },
        {
            heading: "Pros",
            li_1: <>{<a href="/pros">Arbeittech for pros</a>}</>,
            li_2: <>{<a href="https://app.arbeittak.com/provider/register" target='_blank'>Sign up as a pro</a>}</>,
            li_3: <>{<a href="/work">Community</a>}</>,
        },
        {
            heading: "Support",
            li_1: <>{<a href="/help">Help</a>}</>,
            li_2: <>{<a href="/safety">Safety</a>}</>,
            li_3: <>{<a href="/covid">COVID-19 Info</a>}</>,
            li_4: <>{<a href="/terms">Terms of Use</a>}</>,
            li_5: <>{<a href="/privacy">Privacy Policy</a>}</>,
        },
    ]
  return (
    <>
    <div className='footer'>
        <div className='container'>
            <div className='footer-inner'> 
            <div className='row'>
                <div className='col-md-12'>
                    <div className='footer-logo'>
                        <img src={logo} alt="" />
                    </div>    
                </div>
                {data.map((item,i)=>(
                    <div className='col-md-3 col-6'>
                        <div className='footer-menu' key={item}>
                        <h5>{item.heading}</h5>
                            <ul>
                                <li>{item.li_1}</li>
                                <li>{item.li_2}</li>
                                <li>{item.li_3}</li>
                                <li>{item.li_4}</li>
                                <li>{item.li_5}</li>
                            </ul>
                        </div>
                        
                    </div>
                ))}
            </div>
            </div>

        </div>
        <div className='footer-copyright'>
                    <p>Copyright ©  2010-2023 Arbeittech  All rights reserved.</p>
            </div>
    </div>
    
    </>
  )
}

export default Footer