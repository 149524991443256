import React from 'react'
import banner from '../assets/images/Banner/covid.png'
import Tabs from '../global/Tabs'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';


const Covid = () => {
    const data = [
        {
            titles: "Review the pro’s profile",
            descs: "Profiles are designed to give you helpful information to hire the right pro. So take a look through: Customer reviews. See what past customers say about a pro. Verified reviews are from customers who hired a pro on Thumbtack, while unverified reviews are from customers who found the pro somewhere else. You can also see how a pro responded to reviewers, which can help you understand how they interact with their customers.Business information. The profile gives the pro a chance to tell you about their business —and explain why you should hire them. You’ll see things like pictures of their work, a description of their business, why they love what they do, a link to their website and how many times they’ve been hired on Thumbtack.Credentials. We know a pro’s credentials are important to hiring with confidence. A pro’s profile may include:A Top Pro badge, which means they’re highly rated across several reviews from Thumbtack customers. Learn more about what it takes to be a Top Pro here.A license badge, which means we’ve used a public database to verify the license number they provided.A background check badge, which means the account holder submitted a background check and met our criteria to receive the background check badge. For businesses with multiple workers, this does not guarantee the specific person you're working with has passed a background check, so be sure you're comfortable with the person completing your request.",
            keyTitles: "Review the pro’s profile",
            key: "key1"
        },
        {
            titles: "Learn more about the pro",
            descs: "Profiles are designed to give you helpful information to hire the right pro. So take a look through: Customer reviews. See what past customers say about a pro. Verified reviews are from customers who hired a pro on Thumbtack, while unverified reviews are from customers who found the pro somewhere else. You can also see how a pro responded to reviewers, which can help you understand how they interact with their customers.Business information. The profile gives the pro a chance to tell you about their business —and explain why you should hire them. You’ll see things like pictures of their work, a description of their business, why they love what they do, a link to their website and how many times they’ve been hired on Thumbtack.Credentials. We know a pro’s credentials are important to hiring with confidence. A pro’s profile may include:A Top Pro badge, which means they’re highly rated across several reviews from Thumbtack customers. Learn more about what it takes to be a Top Pro here.A license badge, which means we’ve used a public database to verify the license number they provided.A background check badge, which means the account holder submitted a background check and met our criteria to receive the background check badge. For businesses with multiple workers, this does not guarantee the specific person you're working with has passed a background check, so be sure you're comfortable with the person completing your request.",
            keyTitles: "Learn more about the pro",
            key: "key2"
        },
        {
            titles: "Plan ahead to make sure the job is done right",
            descs: "Profiles are designed to give you helpful information to hire the right pro. So take a look through: Customer reviews. See what past customers say about a pro. Verified reviews are from customers who hired a pro on Thumbtack, while unverified reviews are from customers who found the pro somewhere else. You can also see how a pro responded to reviewers, which can help you understand how they interact with their customers.Business information. The profile gives the pro a chance to tell you about their business —and explain why you should hire them. You’ll see things like pictures of their work, a description of their business, why they love what they do, a link to their website and how many times they’ve been hired on Thumbtack.Credentials. We know a pro’s credentials are important to hiring with confidence. A pro’s profile may include:A Top Pro badge, which means they’re highly rated across several reviews from Thumbtack customers. Learn more about what it takes to be a Top Pro here.A license badge, which means we’ve used a public database to verify the license number they provided.A background check badge, which means the account holder submitted a background check and met our criteria to receive the background check badge. For businesses with multiple workers, this does not guarantee the specific person you're working with has passed a background check, so be sure you're comfortable with the person completing your request.",
            keyTitles: "Plan Ahead",
            key: "key3"
        },
        {
            titles: "Think through insurance, permitting, and licensing",
            descs: "Profiles are designed to give you helpful information to hire the right pro. So take a look through: Customer reviews. See what past customers say about a pro. Verified reviews are from customers who hired a pro on Thumbtack, while unverified reviews are from customers who found the pro somewhere else. You can also see how a pro responded to reviewers, which can help you understand how they interact with their customers.Business information. The profile gives the pro a chance to tell you about their business —and explain why you should hire them. You’ll see things like pictures of their work, a description of their business, why they love what they do, a link to their website and how many times they’ve been hired on Thumbtack.Credentials. We know a pro’s credentials are important to hiring with confidence. A pro’s profile may include:A Top Pro badge, which means they’re highly rated across several reviews from Thumbtack customers. Learn more about what it takes to be a Top Pro here.A license badge, which means we’ve used a public database to verify the license number they provided.A background check badge, which means the account holder submitted a background check and met our criteria to receive the background check badge. For businesses with multiple workers, this does not guarantee the specific person you're working with has passed a background check, so be sure you're comfortable with the person completing your request.",
            keyTitles: "Think through insurance",
            key: "key4"
        }
    ];
    return (
        <>
            <section className='covid-banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img src={banner} alt="" />
                        </div>
                        <div className='col-md-6'>
                            <div className='safety-inner'>
                                <h1>A note to our community about COVID-19.</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <div className='safety-banner-1'>
                <div className='container'>
                    <div className='covid-text'>
                        <div className='covid-max-width'>
                            <p>Usually, our business is helping you find your people. Whether you’re a customer looking for just the right
                                pro, or a local business looking for quality customers, our product is all about connecting people. But
                                when experts urge us to keep our distance from each other, that becomes challenging. Things are
                                changing quickly, and we all face uncertainty in the months ahead.<br /><br />

                                At Thumbtack, the health and safety of the communities we serve are our top priority. So we’re
                                adapting, and thinking of ways we can help you adapt, too. Above all, we’ll continue to be here for you:
                                our customers and pros. We’ll get through this together by staying informed, making safe choices and
                                supporting each other.</p>
                        </div>
                    </div>
                    <section className='safety-banner-1'>
                        <div className='container'>
                            <div className='safety-inner-1'>
                                <Tabs
                                    keys={
                                        data.map((item, i) => (
                                            <Nav.Link eventKey={item.key}>{item.keyTitles}</Nav.Link>
                                        ))
                                    }

                                    title={data.map((item, i) => (
                                        <Tab.Pane eventKey={item.key}><h2>{item.titles}</h2><p>{item.descs}</p></Tab.Pane>
                                    ))}
                                />

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Covid