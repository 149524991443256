import React from 'react'
import { Work } from '../components'
import works from '../assets/images/Banner/how-works.png'

const HowWroks = () => {
    return (
        <>
            <section className='about-banner '>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='image-min'>
                                <img src={works} alt="" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='about-max-width'>
                                <h1>Book projects, fast and easy.</h1>
                                <p>It’s never been easier to cross projects off your list. We’ll walk you through the basics.</p>
                                <a href="" className='at-custom-btn'>Hire a pro</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Work />

        </>
    )
}

export default HowWroks