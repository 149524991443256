import React from 'react'
import { Banner, Reviews, Stories, Faqs, Application, Discover } from '../components/index'
import FilterData from '../components/filter/FilterData';

const Home = () => {
  return (
    <>
      <Banner />
      <Application />
      <Stories />
      <FilterData />
      <Discover />
      <Reviews />
      <Faqs />
    </>
  )
}

export default Home