import React from 'react'
import "./Discover.css"
import icon__0 from "../../assets/images/Banner/cat-0.png"
import icon__1 from "../../assets/images/Banner/cat-1.png"
import icon__2 from "../../assets/images/Banner/cat-2.png"
import icon__3 from "../../assets/images/Banner/cat-3.png"
import icon__4 from "../../assets/images/Banner/cat-4.png"
import image_1 from "../../assets/images/Banner/small-icon.png"


const Discover = () => {
    const data = [
        {
          icon: icon__1,
          title: "House Cleaning",
          text: "Avg Price",
          price: "$130 - $330",
          image: image_1
        },
        {
          icon: icon__2,
          title: "Carpenter",
          text: "Avg Price",
          price: "$130 - $330",
          image: image_1
        },
        {
          icon: icon__3,
          title: "Handyman",
          text: "Avg Price",
          price: "$130 - $330",
          image: image_1
        },
        {
          icon: icon__4,
          title: "Plumber",
          text: "Avg Price",
          price: "$130 - $330",
          image: image_1
        },
    ]
  return (
    <>
    <section className='categories-wrapper'>
      <div className='container'>
        <div className="row">
            <div className='col-md-12'>
              <div className='result'>
                  <p>Result <a href=''>(23.987 Items)</a></p>
              </div>
                <div className="item">
                  <div className="stories-time position-relative">
                    <div className="stories-icon">
                      <img src={icon__0} alt="" />
                    </div>
                    <div className='stories-group'>

                    <div className='stories-heading'>
                        <h2>House Paint</h2>
                        {/* <div className='stories-description'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                    </div> */}
                    </div>
                  </div>
                  
                  </div>
                </div>
            </div>
              {data.map((item) => (
                <div className='col-md-3'>
                <div className="item">
                  <div className="discover-time position-relative">
                    <div className="discover-icon">
                      <img src={item.icon} alt="" />
                      <img src={item.image} alt="" className='second-image'/>
                    </div>
                    <div className='discover-group'>

                    <div className='discover-heading'>
                        <h2>{item.title}</h2>
                    </div>
                    <div className='discover-description d-flex justify-content-between'>
                        <p className='font-light'>{item.text}</p>
                        <p>{item.price}</p>
                    </div>
                  </div>
                  
                  </div>
                </div>
                </div>
              ))}
        </div>

    </div>
    </section>
    
    </>
  )
}

export default Discover