import React from 'react'
import "./Work.css"
import icon_1 from "../../assets/images/Banner/work-1.svg"
import icon_2 from "../../assets/images/Banner/work-2.svg"
import icon_3 from "../../assets/images/Banner/work-3.svg"


const Work = () => {
  const data = [
    {
      icon: icon_1,
      title: "Pick a Service",
      desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
      num: 1
    },
    {
      icon: icon_2,
      title: "Browse pros",
      desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
      num: 2
    },
    {
      icon: icon_3,
      title: "And You're done",
      desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
      num: 3
    },
  ]
  return (
    <>
      <section className='categories-wrapper'>
        <div className='container'>
          <div className='categories-main mb-5'>
            <div className='categories-heading'>
              <h2>How it Works</h2>
            </div>
            <div className='categories-paragraph'>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
            </div>
          </div>

          <div className=''>
            <div className="row">
              {data.map((item) => (
                <div className='col-md-4 p-0 m-0'>
                  <div className="item">
                    <div className={`work-time m-0 work-time-${item.num}`}>
                      <div className="work-icon">
                        <img src={item.icon} alt="custom-icon" />
                      </div>
                      <div className='work-group'>

                        <div className='work-heading'>
                          <h2>{item.title}</h2>
                          <div className='work-description'>
                            <p>{item.desription}</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>

        </div>
      </section>
    </>
  )
}

export default Work