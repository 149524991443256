import { useEffect } from "react"

const Filter = ({ activeGenre, setActiveGenre, setFiltered, popular }) => {

  useEffect(() => {
    if (activeGenre === 0) {
      setFiltered(popular)
      return
    }
    const filtered = popular.filter(movie => movie.genre_ids.includes(activeGenre));
    setFiltered(filtered);
  }, [activeGenre])
  

  return (
    <div className="filter-container">
      <div className="filter-title">
          <h4>Discover</h4>
      </div>
      <div className="filter-type">

      <button 
        className={activeGenre === 0 ? "active" : ""}
        onClick={() => setActiveGenre(0)}
      >
       Home Maintenance
      </button>
      <button 
        className={activeGenre === 35 ? "active" : ""}
        onClick={() => setActiveGenre(35)}
      >
       Home remodeling
      </button>
      <button 
        className={activeGenre === 28 ? "active" : ""}
        onClick={() => setActiveGenre(28)}
      >
        Weddings
      </button>
      <button 
        className={activeGenre === 24 ? "active" : ""}
        onClick={() => setActiveGenre(24)}
      >
        Events
      </button>
      <button 
        className={activeGenre === 20 ? "active" : ""}
        onClick={() => setActiveGenre(20)}
      >
        More
      </button>
      </div>

    </div>
  )
}

export default Filter