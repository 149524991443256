import React from 'react'
import './Banner.css'


const Banner = () => {
  return (
    <>
    <section className='banner-wrapper'>
        <div className='banner-main'>
            <div className='banner-heading pb-2'>
                <h1>Ready to Remodel? Find Home Professionals You Can Trust.</h1>
            </div>
            <div className='banner-paragraph pb-3'>
                <p>Thousands of homeowners connect with the right pro every week for their remodeling projects.</p>
            </div>
            <div className='banner-input pb-3'>
       
              <label className='position-relative w-100 d-flex justify-content-center align-items-center'>
                <input type="text" placeholder='What services do you need?' />
                <span className='zip-code'>74600</span>
              </label>
              <button className='button'>Get started</button>
     
            </div>
            <div className='banner-input-description'>
                <p>try searching for a <span>Plumber</span>, <span>Electrician</span> or <span>handyman</span></p>
            </div>
        </div>
    </section>
    
    
    
    </>
  )
}

export default Banner