import React from 'react'
import about from "../assets/images/Banner/about-us.png"
import banner from "../assets/images/Banner/about-banner.png"
import icon_1 from "../assets/images/Banner/share.png"
import icon_2 from "../assets/images/Banner/world.png"
import icon_3 from "../assets/images/Banner/70m.png"

import { Cta, Reviews } from '../components';


const About = () => {
    const data = [
        {
            number: "450+",
            description: "Hundreds of client login monthly"
        },
        {
            number: "120+",
            description: "Languages & Countries"
        },
        {
            number: "135+",
            description: "Percent yearly turnover increase"
        },
        {
            number: "324+",
            description: "Millions of Active accounts"
        }
    ];
    const datas = [
        {
            image: icon_1,
            title: "500+ project categories",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,eiusmod tempor incididunt ut labore et dolore magna."
        },
        {
            image: icon_2,
            title: "Available nationwide",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,eiusmod tempor incididunt ut labore et dolore magna."
        },
        {
            image: icon_3,
            title: "70 million+ projects started",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,eiusmod tempor incididunt ut labore et dolore magna."
        }
    ];
    return (
        <>
            <section className='blog-banner'>
                <div className='container'>
                    <div className='blogs-inner banner-main'>
                        <h1>About</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </section>

            <section className='about-second-section'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='about-left'>
                                <h3>Few words about us</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img src={about} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-section-3'>
                <div className='container'>
                    <div className='row'>
                        {data.map((item, i) => (
                            <div className='col-md-3'>
                                <div className='about-bg'>
                                    <h5>{item.number}</h5>
                                    <p>{item.description}</p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </section>
            <section className='about-section-3'>
                <div className='container'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-md-6'>
                            <div className='about-img'>
                                <img src={banner} alt="" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h2>What we provide for your growth</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            {datas.map((item, i) => (
                                <div className='d-flex mb-4 custom-mr align-items-center'>
                                    <div className='related-image'>
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className='related-title'>
                                        <h5>{item.title}</h5>
                                        <span>{item.description}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Reviews />
            <Cta />
        </>
    )
}

export default About