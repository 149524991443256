import React from 'react'
import { Faqs } from '../components'

const Question = () => {
    return (
        <>
            <div className='questions-banner'>
                <div className='container'>
                    <div className='text-center pb-5 pt-5 '>
                        <h1>What do you want to know?</h1>
                    </div>
                </div>
                <Faqs />
                <Faqs />
                <Faqs />
            </div>


        </>
    )
}

export default Question