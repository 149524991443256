import React from 'react'
import ServicesCards from '../global/ServicesCards'
import { Application, Faqs, Reviews } from '../components'

const Search = () => {
    return (
        <>
            <section className='search-banner'>
                <div className='container'>

                    <div className='search-main'>
                        <div className='banner-heading pb-2'>
                            <h3>House Cleaners</h3>
                        </div>
                        <div className='banner-paragraph pb-3'>
                            <p>Confirm your location to see quality pro's Near You!</p>
                        </div>
                        <div className='banner-input pb-3'>

                            <label className='position-relative w-100 d-flex justify-content-center align-items-center'>
                                <input type="text" placeholder='What services do you need?' />
                                <span className='zip-code'>74600</span>
                            </label>
                            <button className='button'>Get started</button>

                        </div>
                    </div>

                </div>
            </section>
            <ServicesCards />
            <ServicesCards />
            <ServicesCards />
            <Reviews />
            <Faqs />
            <Application />


        </>
    )
}

export default Search