import React from 'react'
import card1 from "../assets/images/Banner/help1.png"
import card2 from "../assets/images/Banner/help2.png"
import card3 from "../assets/images/Banner/help3.png"


const HelpCards = () => {
    const data = [
        {
            image: card1,
            title: "How to get started",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quia voluptatum",
        },
        {
            image: card2,
            title: "How to get started",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quia voluptatum",
        },
        {
            image: card3,
            title: "How to get started",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quia voluptatum",
        },
    ];
    return (
        <>
            <div className='row'>
                {data.map((item, i) => (
                    <div className='col-md-4'>
                        <div className='help-card'>
                            <div className='help-header'>
                                <img src={item.image} alt="help card" />
                            </div>
                            <div className='help-content'>
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))}

            </div>

        </>
    )
}

export default HelpCards