import React from 'react'
import { Application, Stories } from '../components'
import Cards from '../global/Cards'


const Categories = () => {
    return (
        <>
            <section className='categories-banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>

                        </div>
                        <div className='col-md-6'>
                            <div className='categories-inner'>
                                <h1>Home Improvement!</h1>
                                <p>Thousands of homeowners connect with the right pro every week for their remodeling projects.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='categories-inner-1'>
                <div className='container'>
                    <h5>Looking for something else?</h5>
                    <p>No problem we have pros for every kind of job.</p>
                    <div className='banner-input pb-3'>

                        <label className='position-relative w-100 d-flex justify-content-center align-items-center'>
                            <input type="text" placeholder='What services do you need?' />
                            <span className='zip-code'>74600</span>
                        </label>
                        <button className='button'>Get started</button>

                    </div>
                </div>
            </section>
            <Cards
                heading="Monthly and seasonal maintenance"
                titles="House Cleaning"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles1="Lawn mowing & Trimming"
                desc1="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles2="Window Washing"
                desc2="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"

            />
            <Cards
                heading="Yearly check-ups indoors and out"
                titles="Tree Trimming"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles1="Exterior Painting"
                desc1="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles2="Pressure washing"
                desc2="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"

            />
            <Cards
                heading="Projects for the weekend"
                titles="Interior Painting"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles1="Carpet Cleaning"
                desc1="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles2="Junk Removal"
                desc2="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"

            />
            <Cards
                heading="Projects for the weekend"
                titles="Pest Control"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles1="Bed Bug Extermination"
                desc1="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles2="Rodent Removal"
                desc2="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"

            />
            <Cards
                heading="In case of emergency"
                titles="Property Management"
                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles1="Sod Installation"
                desc1="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                titles2="Tile & Grout Cleaning"
                desc2="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"

            />
            <Application />







        </>
    )
}

export default Categories